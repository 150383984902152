<template>
    <div class="appointment">
        <div class="page-top">
            <div class="logo" @click="goto('/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
            <div class="titlebox">专家与门诊预约</div>
        </div>
        <div class="contentbox">
            <div class="left-con">
                <div class="itemlist">
                    <!-- <div class="item" v-for="(item, index) in doctors" :key="index" @click="contentShow(item.id)"> -->
                    <div class="item" v-for="(item, index) in doctors" :key="index">
                        <div class="img">
                            <div class="picbox">
                                <div class="pic" :style="{backgroundImage: 'url(' + baseUrl + item.imgUrl +')'}"></div>
                            </div>
                        </div>
                        <div class="itemtitle">
                            <div class="title">{{item.title}}<span class="subtitle">{{item.categoryTitle}}</span></div>
                            <div class="detail">
                                <div class="d1">
                                    <div class="picbox">
                                        <div class="pic" style="background-image: url(/img/book.png)"></div>
                                    </div>
                                    <div class="txt">主治：{{item.fields.zhuzhi}}</div>
                                </div>
                                <div class="d2">
                                    <div class="picbox">
                                        <div class="pic" style="background-image: url(/img/time.png)"></div>
                                    </div>
                                    <div class="txt">门诊时间：{{item.fields.shijian}}</div>
                                </div>
                                <div class="d3">服务承诺：{{item.fields.fuwu}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-con">
                <div class="taglist">
                    <div class="tag" :class="{active:tagindex===index}" v-for="(item, index) in tag" :key="index" @click="tagclick(item.id,index)">{{item.title}}</div>
                    <div class="tag2" v-for="(item, index) in taglist" :key="index" @click="contentShow(item.id)">{{item.title}}</div>
                </div>
                <div class="guide">
                    <div class="titlebox">医院门诊时间</div>
                    <div class="times" v-html="base.sjMenZhen"></div>
                    <!-- <div class="t1">
                        <div class="day">周一<span></span>~<span></span>周五：</div>
                            <div class="time">
                            上午8:00~11:30&emsp;下午13:30~17:00
                            </div>
                    </div>
                    <div class="t2"><div class="day">周<span></span>六：</div><div class="time">上午8:00~11:30</div></div>
                    <div class="t3"><span>*</span>国家法定节假日开诊时间按实际情况提前具体通知</div> -->
                    <div class="btns">
                        <button class="btn" @click="contentShow(item.id)" v-for="(item, index) in btns" :key="index">
                            <div class="txt">{{item.title}}</div>
                            <div class="arrow">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/btn_arrow.png)"></div>
                                </div>
                            </div>
                        </button>
                        <!-- <button class="btn">
                            <div class="txt">健康云APP预约指引</div>
                            <div class="arrow">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/btn_arrow.png)"></div>
                                </div>
                            </div>
                        </button>
                        <button class="btn">
                            <div class="txt">就诊流程及医保支付指引</div>
                            <div class="arrow">
                                <div class="picbox">
                                    <div class="pic" style="background-image: url(/img/btn_arrow.png)"></div>
                                </div>
                            </div>
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="popup" :class="{active: detailShow}">
            <div class="shadow" @click="detailShow = false"></div>
            <div class="detail-popup">
                <div class="titlebox">
                    <div class="atitle">{{popupTitle}}</div>
                    <div class="close" @click="detailShow = false">
                        <div class="picbox">
                            <div class="pic" style="background-image: url(/img/close.png)"></div>
                        </div>
                    </div>
                </div>
                <div class="conbox" v-html="string" style="white-space:pre-wrap;"></div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { getBase } from "@/js/request";
import { getPro } from "@/js/request";
import { getInfo,getCategory2,getPage } from "@/js/request";

export default {
    name: 'Appointment',
    components: {

    },
    data() {
        return {
            base: {},
            baseUrl: '',
            doctors: [],
            tag: [],
            tagindex:0,
            taglist: [],
            btns: [],
            popupTitle: '',
            string: '',
            detailShow: false,

        }
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({path: adress, query: cate});
        },
        detailPopup() {
            this.detailShow = !this.detailShow;
        },
        contentShow(id) {
            getInfo(id).then(res => {
                let content = res.data.content;
                if(content != null && content != ""){
                    this.string = content.replaceAll("src=\"", "src=\"" + this.baseUrl);
                }else{
                    this.string = res.data.content;
                }
                this.popupTitle = res.data.title;
            });
            setTimeout(() => {
                this.detailShow = true;
            }, 500);
        },
        tagclick(cid,index){
            this.tagindex=index;
            getPage(1,cid).then(res =>{
                this.taglist = res.data;
            });
        }
    },
    mounted() {
        this.$root.showRight();
        getBase().then(res => {
            this.base = res.data;
            this.baseUrl = res.config.baseURL;
        });
        getPro().then(res => {
            this.doctors = res.data;
            this.doctors.forEach(element => {
                getInfo(element.id).then(res => {
                });
            });
        });
        getCategory2(8,55).then(res =>{
            this.tag = res.data;
            getPage(1,this.tag[0]['id']).then(res =>{
                this.taglist = res.data;
            });
        });
        getPage(1,54).then(res =>{
            this.btns = res.data;
        });
        // for(let id = 15; id <= 24; id++) {
        //     getContent(id).then(res => {
        //         if(id >= 18) {
        //             this.tag[id-18] = res.data.title;
        //         } else {
        //             this.btns[id-15] = res.data.title;
        //         }
        //     });
        // }
    }
}
</script>

<style lang="scss">
@media (max-height: 1000px) {
    .appointment{
        padding: 20px 0 60px 0!important;
    }
    .page-top{
        margin-bottom: 20px!important;
    }
    // .appointment .contentbox{
    //     height: 780px!important;
    // }
    // .appointment .contentbox .right-con .guide .titlebox{
    //     margin-bottom: 10px!important;
    // }
}
.appointment {
    padding: 60px 0;
    box-sizing: border-box;
    // max-height: 100vh;
    width: 1400px;
    margin: 0 auto;
    .page-top {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }
        .titlebox {
            @include sys-font(42px, #000, bold);
            margin-left: 30px;
            padding-left: 30px;
            position: relative;
            &::before {
                content: '';
                width: 2px;
                height: 46px;
                background: #000;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }
    .contentbox {
        @include sys-boxsize(100%, auto, 20px, #fff);
        display: flex;
        box-shadow: 0px 0px 50px 0px rgba(63,101,224,0.05);
        padding: 60px;
        box-sizing: border-box;
        position: relative;
        .left-con {
            flex: 1;
            height: 100%;
            overflow-y: auto !important;
            &::-webkit-scrollbar {
                width: 3px;
            }
            &::-webkit-scrollbar-thumb {
                background: $acolor;
                border-radius: 2px;
            }
            &::-webkit-scrollbar-resizer {
                background: $acolor;
            }
            &::-webkit-scrollbar-track-piece {
                background: #fff;
                border-radius: 2px;
            }
            .itemlist {
                width: 100%;
                .item {
                    display: flex;
                    width: 100%;
                    margin-bottom: 40px;
                    cursor: pointer;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .img {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        margin-right: 30px;
                        flex: none;
                        overflow: hidden;
                        @include sys-picbox(100%, 100%, cover);
                    }
                    .itemtitle {
                        .title {
                            display: flex;
                            align-items: flex-end;
                            @include sys-text-hide(1);
                            @include sys-font(28px, #000, bold);
                            line-height: 36px;
                            margin-bottom: 12px;
                            .subtitle {
                                @include sys-font(20px, #000, bold);
                                line-height: 28px;
                                margin-left: 6px;
                            }
                        }
                        .detail {
                            @include sys-picbox(16px, 16px);
                            .picbox {
                                flex: none;
                                margin-top: 3px;
                            }
                            .txt {
                                @include sys-text-hide(3);
                                @include sys-font(16px, #333);
                                line-height: 22px;
                                margin-left: 20px;
                            }
                            .d1, .d2 {
                                display: flex;
                                margin-bottom: 12px;
                            }
                            .d3 {
                                @include sys-text-hide(3);
                                @include sys-font(16px, #7389D0);
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
        .right-con {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 440px;
            height: 100%;
            flex: none;
            margin-left: 60px;
            .taglist {
                display: flex;
                flex-wrap: wrap;
                width: calc(100% + 12px);
                margin: 0 -6px;
                max-height: 800px;
                overflow: hidden;
                flex: none;
                .tag {
                    @include sys-boxsize(140px, 44px, 4px, #FFFFFF);
                    @include sys-text-hide(1);
                    @include sys-font(16px, $acolor);
                    line-height: 44px;
                    text-align: center;
                    border: 2px solid #B2C4FF;
                    box-sizing: border-box;
                    margin: 0 5px 10px;
                    cursor: pointer;
                    transition: all .3s;
                    &:hover,&.active {
                        background: $acolor;
                        color: #fff;
                    }
                }
                .tag2 {
                    @include sys-boxsize(auto, 44px, 4px, #FFFFFF);
                    @include sys-text-hide(1);
                    @include sys-font(16px, $acolor);
                    line-height: 44px;
                    text-align: center;
                    // border: 2px solid #B2C4FF;
                    box-sizing: border-box;
                    margin: 0 5px 10px;
                    cursor: pointer;
                    transition: all .3s;
                    &:hover {
                        background: $acolor;
                        color: #fff;
                    }
                }
            }
            .guide {
                .titlebox {
                    @include sys-font(28px, #000, bold);
                    margin-bottom: 40px;
                }
                .times {
                    @include sys-font(16px, #333);
                    line-height: 26px;
                    letter-spacing: 1px;
                    margin-bottom: 50px;
                    white-space: pre-wrap;
                    
                    &>div {
                        display: flex;
                        margin-bottom: 10px;
                    }
                    .t3 {
                        span {
                            color: #FF0000;
                        }
                    }
                    .day {
                        width: 100px;
                        flex: none;
                        display: flex;
                        span {
                            flex: 1;
                        }
                    }
                    .time {
                        flex: 1;
                    }
                }
                .btns {
                    .btn {
                        @include sys-boxsize(100%, 80px, 10px, $acolor);
                        @include sys-flex(space-between, row);
                        margin-bottom: 10px;
                        padding: 30px;
                        box-sizing: border-box;
                        .txt {
                            @include sys-text-hide(1);
                            @include sys-font(20px, #fff, bold);
                            flex: 1;
                        }
                        .arrow {
                            flex: none;
                            padding: 10px;
                            @include sys-picbox(14px, 10px);
                        }
                    }
                }
            }
        }
    }
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        &.active {
            opacity: 1;
            visibility: visible;
            .detail-popup {
                opacity: 1;
            }
        }
        .shadow {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .8);
        }
        .detail-popup {
            @include sys-flex();
            @include sys-boxsize(1200px, 800px, 20px, #fff);
            padding: 0 60px 60px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity .6s;
            opacity: 0;
            .titlebox {
                @include sys-flex(space-between, row);
                width: 100%;
                height: 120px;
                margin-bottom: 40px;
                flex: none;
                position: relative;
                &::after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #EEEEEE;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                .atitle {
                    @include sys-text-hide(1);
                    @include sys-font(36px, #000, bold);
                }
                .close {
                    @include sys-radius-btn(40px, 40px, #000);
                    @include sys-picbox(16px, 16px);
                    overflow: hidden;
                    transition: transform .3s;
                    cursor: pointer;
                    &:hover {
                        transform: rotate(90deg);
                    }
                }
            }
            .conbox {
                flex: 1;
                width: 100%;
                padding: 0 30px 30px 30px;
                box-sizing: border-box;
                @include sys-font(20px, #000);
                overflow-y: auto !important;
                &::-webkit-scrollbar {
                    width: 3px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $acolor;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-resizer {
                    background: $acolor;
                }
                &::-webkit-scrollbar-track-piece {
                    background: #fff;
                    border-radius: 2px;
                }
                p {
                    text-indent: 40px;
                }
            }
        }
    }
}
</style>